body {
  margin: 0;
}

.logo-container {
  height: 32px;
  margin: 0px 32px 0px 8px;
}

.logo {
  height: 100%;
}

.App-body {
  width: calc(100% - 48px);
  margin: 0px 24px;
}

.App-instructions {
  padding: 32px 0px 16px 0px;
  font-weight: 300;
  font-size: 14px;
}
